// 
//  Authentication
// 

.account-page-bg{
    background-image: url(../images/bg-auth.jpg);
    background-size: cover;
    background-position: center center;
}
.bg-color {
    background-color: $white;
}
.header-box {
    background-color: $primary;
}
.auth-button a{
    color: $dark;
    font-weight: 500;
    border: 1px solid var(--bs-gray-400);
}